import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/cinemablend.css';

loadCSS('cinemablend-site');

export const CinemablendSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default CinemablendSiteWrapper;
